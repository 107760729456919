@use "../../../theme/theme";

.blockscreenmsg-main {
    display: flex;
    padding: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 300px;
    height: 200px;

    .--msg {
        display: flex;
        align-items: center;
    }

    .load-4 {
        height: 25%;
    }

    .line {
        display: inline-block;
        width: 15px;
        height: 15px;
        background-color: #0D8390;
        margin: 2px;
    }


    .load-4 .line:nth-last-child(4) {
        animation: loadingC 1.6s 1.1s linear infinite;
    }

    .load-4 .line:nth-last-child(3) {
        animation: loadingC 1.6s 1.2s linear infinite;
    }

    .load-4 .line:nth-last-child(2) {
        animation: loadingC 1.6s 1.3s linear infinite;
    }

    .load-4 .line:nth-last-child(1) {
        animation: loadingC 1.6s 1.4s linear infinite;
    }


    @keyframes loadingC {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(0.25);
            background-color: theme.$base-color-disabled;
        }

        75% {
            transform: scale(0.25);
            background-color: theme.$base-color-disabled;
        }

        100% {
            transform: scale(1);
        }

    }

}