@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: local('Open Sans'), url('./fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('Open Sans'), url('./fonts/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: local('Open Sans'), url('./fonts/OpenSans/OpenSans-Bold.ttf');
}