@use "../../../theme/theme";

.notify-main {
    position: fixed;
    display: flex;
    left: 50%;
    top: 60px;
    height: 50px;
    transform: translate(-50%, 0);
    justify-content: center;
    transition: all 1s;
    z-index: 11;

    .--msgbox {
        color: theme.$white-color;
        align-items: center;
        display: flex;
        flex-direction: row;
        min-width: 500px;
        justify-content: space-between;

        &.--success {
            background: theme.$success-color;
        }

        &.--info {
            background: theme.$base-color;
        }

        &.--error {
            background: theme.$error-color;
        }

        &.--warning {
            background: theme.$warning-color;
            color: theme.$black-color;
        }

        .--spanblock {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                padding: 0px 5px;
                align-self: center;
            }
        }

        img {
            padding: 1vh;

            &.--close {
                height: 10px;
                align-self: center;
                cursor: pointer;
            }

            &.--type {
                align-self: center;
                margin-top: 2px;
            }
        }
    }

    .--undo {
        height: 100%;
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1vh;

        &:hover {
            border: 1px solid theme.$black-color;
        }
    }
}