$chip-y-spacing: 5px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 15px;
$chip-background: #fff;
$chip-color: #0d8390;
$chip-min-height: 36px;

.chips-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .--label-box {
    color: #53565a;
    margin-bottom: 3px;
  }

  .--chipsbox-main {
    background: #ffffff;
    border: 1px solid #d0d0ce;
    // border-radius: 4px;
    align-items: center;
    display: inline-flex;
    padding: 3px;

    .--chips {
      width: 100%;
      max-height: 12vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .--chips-input {
      width: 100%;
      display: inline-block;
      // margin-bottom: $chip-x-spacing;
      // margin-left: $chip-x-spacing * 2;
      border: 0;
      outline: none;
    }

    .chip {
      display: inline-block;
      margin-bottom: $chip-x-spacing;
      margin-right: 5px;
      border: 1px solid $chip-color;

      .chip-value {
        display: inline-block;
        max-width: 15vw;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: $chip-y-spacing;
        background: $chip-background;
        color: $chip-color;
      }

      .chip-delete-button {
        background: $chip-background;
        color: $chip-color;
        border: 0;
        border-radius: 0 $chip-border-radius $chip-border-radius 0;
        padding: $chip-x-spacing $chip-x-spacing * 2;
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: -$chip-button-width;
      }
    }
  }
}
.danger-icon {
  filter: invert(100%) hue-rotate(0deg) saturate(100%) brightness(100%)
    contrast(100%);
}
