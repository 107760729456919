@use "../../../../theme/theme";

.tags-input {
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: calc(100% - 10px);
  padding: 0 5px;
  overflow-x: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  // border: 1px solid theme.$border-color;

  // &:focus-within {
  //   border: 1px solid theme.$base-color;
  // }

  .input-class {
    flex: 1;
    border: none;
    height: 27px;
    font-size: 14px;
    padding: 5px 0 0 0;

    &:focus {
      outline: transparent;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
}

.tag {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-size: 14px;
  list-style: none;
  margin: 5px 5px 2px 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48)),
    linear-gradient(0deg, #0D8390, #0D8390);

  .tag-title {
    width: max-content;
  }

  .tag-close-icon {
    margin-left: 5px;
    cursor: pointer;
  }
}

.tags-input::-webkit-scrollbar {
  border-radius: 20px;
  width: 2px;
  height: 2px;
}

.tags-input::-webkit-scrollbar-track {
  background-color: #00000022;
}

.tags-input::-webkit-scrollbar-thumb {
  background-color: #63666a;
}
