
.btn-main {
    border: none;
    text-align: center;
    /* padding: max(5px,0.52vw) max(10px,0.83vw); */
    padding: 4px 10px 6px 10px;
    background: #0D8390;
    color: white;
    cursor: pointer;
    display: inline-flex;

    &.error {
        background: #da291c;
    }

    &.disabled {
        background: #81BFC5;
        cursor: default;
        opacity: 0.6;
    }

    &.outline {
        background: #86BC25;
        color: #86BC25;
        border-color: #86BC25;

        &.error {
            color: #da291c;
            border-color: #da291c;
        }

        &.disabled {
            color: #9ab071;
            border-color: #9ab071;
        }
    }
}