@import './theme/theme';
@import './theme/fonts';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.ReactModal__Overlay {
  background-color: rgba(61, 61, 61, 0.75) !important;
  z-index: 1;
}

.DDSButton__text {
  padding: 0 !important;
}

::-webkit-scrollbar {
  border-radius: 20px;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #00000022;
}

::-webkit-scrollbar-thumb {
  background-color: #63666a;
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.dds-h1 {
  font-size: $fs-56;
  font-weight: 600;
  line-height: 72px;
}

.dds-h2 {
  font-size: $fs-40;
  font-weight: 600;
  line-height: 52px;
}

.dds-h3 {
  font-size: $fs-32;
  font-weight: 600;
  line-height: 40px;
}

.dds-h3-sm {
  font-size: $fs-32;
  font-weight: 500;
  line-height: 40px;
}

.dds-h4 {
  font-size: $fs-24;
  font-weight: 700;
  line-height: 36px;
}

.dds-h4-lm {
  font-size: $fs-24;
  font-weight: 600;
  line-height: 36px;
}

.dds-h4-sm {
  font-size: $fs-24;
  font-weight: 500;
  line-height: 36px;
}

.dds-h5 {
  font-size: $fs-20;
  font-weight: 600;
  line-height: 24px;
}

.dds-h5-lm {
  font-size: $fs-18;
  font-weight: 600;
  line-height: 24px;
}

.dds-h5-sm {
  font-size: $fs-16;
  font-weight: 500;
  line-height: 24px;
}

.dds-h6 {
  font-size: $fs-16;
  font-weight: 600;
  line-height: 20px;
}

.dds-h7 {
  font-size: $fs-14;
  font-weight: 400;
  line-height: 20px;
}

.dds-h7-sm {
  font-size: $fs-16;
  font-weight: 400;
  line-height: 20px;
}

.dds-body {
  font-size: $fs-14;
  font-weight: 500;
  line-height: 20px;
}

.dds-body-semibold {
  font-size: $fs-14;
  font-weight: 600;
  line-height: 20px;
}

.dds-body-bold {
  font-size: $fs-14;
  font-weight: 700;
  line-height: 20px;
}

.dds-link {
  font-size: $fs-14;
  font-weight: 500;
  line-height: 20px;
}

.dds-tost {
  font-size: $fs-14;
  font-weight: 400;
  line-height: 20px;
}

.dds-label {
  font-size: $fs-12;
  font-weight: 500;
  line-height: 16px;
  color: $label-color;
}

.dds-label-sm {
  font-size: $fs-10;
  font-weight: 500;
  line-height: 16px;
  color: $label-color;
}

.dds-label-sm-light {
  font-size: $fs-14;
  font-weight: 400;
  line-height: 20px;
  color: $disabled-input-color;
}

.break-word-white-space {
  font-size: $fs-14;
  font-weight: 600;
  line-height: 20px;
  color: $black-color;
  word-break: auto-phrase;
}

.dds-label-semibold {
  font-size: $fs-12; // max(12px, 0.63vw);
  font-weight: 600;
  line-height: 16px;
}

.dds-link-sm {
  font-size: $fs-12;
  font-weight: 500;
  line-height: 16px;
}

.dds-icon {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.font-style-italic {
  font-style: italic;
}

.justify-right {
  justify-content: right;
}

.fw-600 {
  font-weight: 600;
}

.justify-left {
  justify-content: left;
}

.width-100 {
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.error {
  color: $error-color;
}

.error-msg {
  color: $error-color;
  font-size: 0.625em;
  margin: .5vh 0;
}

.base-color {
  color: $base-color;
}

.btn-danger {
  background-color: $error-color;
}

.btn-success {
  background-color: $success-color;
}

.daterange {
  width: max-content;
  min-width: 25vw;
  margin-top: 2vh;

  label {
    font-size: $fs-12;
    font-weight: 500;
    line-height: 16px;
    color: $label-color;
  }
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-success {
  background-color: $success-color;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.disabled {
  cursor: default;
  pointer-events: none;
  opacity: .5;
}

.text-center {
  text-align: center;
}

.filter {
  &-title {
    padding: 1vh;
  }

  &-container {
    height: 0;
    width: 0;
    position: relative;

    &-box {
      position: relative;
      left: -5vw;
      width: 10vw;
      top: 4vh;
      padding-top: 1vh;
      border: 1px solid $border-color;
      background: $white-color;
      z-index: 100;

    }
  }

  &-no-result {
    text-align: center;
    color: $black-color;
  }

  &-checkbox {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    cursor: pointer;
    padding: 1vh;

    &:hover {
      background-color: $border-color;
    }
  }
}