@use '../../../theme/theme';

.inputbox-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  input:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }

  .--inputbox-main {
    background: #ffffff;
    border: 1px solid #d0d0ce;
    align-items: center;
    display: inline-flex;
    padding: 5px;

    input {
      outline: none;
      border: none;
      width: 100%;
    }

    .suffix {
      color: theme.$util-box-separator-color;
      font-weight: 400;
    }
  }

  .inValid {
    border-color: theme.$error-color;
  }

  .--label-box {
    color: #53565a;
    margin-bottom: 3px;
  }
}