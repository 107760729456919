@use '../../../../theme/theme';

.ChangeLogTabel {
  width: 100%;
  padding-left: 2vw;

  &-header {
    padding: 2vh 0;
    border-bottom: 1px solid theme.$border-color;
  }

  &-subHeader {
    display: flex;
    gap: 1vw;
    margin: 2vh 0;
  }

  &-searchbox {
    border: 1px solid theme.$border-color;
  }

  &-pending {
    color: theme.$label-color;
  }

  &-approved {
    color: theme.$success-color;
  }

  &-rejected {
    color: theme.$error-color;
  }

  &-title {
    padding-right: 2vw;

    &-row {
      color: theme.$header-color !important;
      flex-wrap: nowrap;
      padding: 10px 1vw;
      // align-items: center;
      border-bottom: 1px solid theme.$border-color;
      word-break: normal;

      &-col {
        display: flex;
        padding-right: 1vh;
        word-break: normal;

        &-icon-sort {
          &-ASC {
            transform: rotate(180deg);
          }

          &-DESC {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &-body {
    border: 1px solid theme.$border-color;
    padding: 0 1vw;


    &-row {
      flex-wrap: nowrap;
      padding: 10px 1vw;
      border-bottom: 1px solid theme.$border-color;
      // align-items: center;
      word-break: normal;

      &-col {
        padding-right: 1vh;
        word-break: normal;
      }

    }
  }

  &-pagination {
    display: flex;
    justify-content: center;
    margin: 3vh 0;
  }

  &-action {
    display: flex;
    gap: 1vh;

    &-reject {
      box-shadow: theme.$error-color 0px 0px 0px 1px inset !important;
      color: theme.$error-color !important;
    }
  }

  &-nosearchdata {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    flex-direction: column;
    text-align: center;

    p {
      font-size: theme.$fs-18;
      font-weight: 600;
    }

    &-img {
      height: 50vh;
    }
  }
}