.main-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.main-grid-item {
  word-break: break-all;
}
