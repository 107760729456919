@use '../../../../theme/theme';

.orgLevel {
  &-tabs {
    border: 1px solid theme.$border-color;
    display: flex;
    min-width: 30%;
    width: fit-content;
    cursor: pointer;

    &-divider {
      border: 1px solid theme.$border-color;
      height: 10px;
      margin: auto;
    }

    &-tab,
    &-tab-active {
      padding: 10px 0;
      width: 100%;
      text-align: center;
    }

    &-tab-active {
      color: theme.$white-color;
      background: theme.$base-color;
    }
  }

  &-header {
    margin-top: 3vh;
  }

  &-analytics {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    gap: 2vw;
    overflow: hidden;
  }

  &-divider {
    border-bottom: 1px solid theme.$border-color;
    margin: 4vh 0;
  }

  &-graph {
    border: 1px solid theme.$border-color;
    width: 100%;
    padding: 1vh 0;

    &-back {
      color: theme.$base-color;
      display: inline-flex;
      gap: .5vw;
      padding: 0 1vw;
      align-items: center;
    }

    &-label {
      padding: 1vw;

      &-sub {
        padding: 1vh 1vw;
        // height: 20px;
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      gap: 2vw;
      margin-top: 3vh;
    }
  }

  &-hours {
    &-graph {
      &-container {
        border: 1px solid theme.$border-color;
        width: 100%;
        margin-top: 3vh;
      }
    }
  }

  &-noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      font-size: theme.$fs-28;
      font-weight: 600;
    }

    p {
      font-size: theme.$fs-16;
      font-weight: 400;
    }
  }

  &-userView {
    &-back {
      color: theme.$base-color;
      display: inline-flex;
      gap: .5vw;
      align-items: center;
      margin-bottom: 2vh;
    }
  }
}

.donutChart-container {
  height: 60vh;
  width: 100%;
}