.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-text {
    margin: 0px 10px;
  }

  &-input {
    width: 40px;
    height: 25px;
    padding: 6px;
    text-align: center;
  }
}