@use '../../../../../theme/theme';

.progressBar {
  &-progress {
    display: flex;
    height: 1.25rem;
    overflow: hidden;
    background-color: theme.$progress-bar-bg;

    &-bar {
      display: flex;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      // transition: width 6s ease;
    }
  }

  &-tooltip {
    position: absolute;
    border: 1px solid theme.$border-color;
    background: theme.$white-color;
    padding: 1vh 1vw;
    width: fit-content;
    z-index: 1000;
  }
}