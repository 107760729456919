@use '../../../theme/theme';

.dropdown-main {
  cursor: pointer;

  .main-block-screen {
    z-index: 1000 !important;
  }
}

.dropdown-checkbox {
  box-sizing: border-box;

  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #000000;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: theme.$white-color;
  border: 1px solid #000000;
}

.container input:checked~.checkmark {
  background-color: theme.$base-color;
  border: none !important;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5.5px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.box-button {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background: theme.$white-color;
  justify-content: space-between;
  padding: 0 5px;
  border: 1px solid theme.$border-color;
  margin-bottom: 5px;
  cursor: pointer;

  &-text {
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 2px;
  }
}

.dropdown-custom-input {
  border: none;
  width: 90% !important;
  outline: none;
  background-color: inherit;
}

.dropdown-chips {
  display: flex;
  gap: 1%;
  flex-wrap: wrap;
  max-height: 110px;
  padding: 0 5px 5px;
  overflow: auto;
  border: 1px solid theme.$border-color;

  &-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
    color: theme.$white-color;
    background-color: theme.$teal-color;
    margin-top: 5px;

    &-cancel {
      height: 10px;
      width: 10px;
    }
  }
}

.chip-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 264px;
}

.dropdown-item {
  overflow: hidden !important;
}

.chip-tooltip {
  z-index: 999;
  max-width: 264px;
  word-break: break-all;
  text-align: center;
  margin-bottom: 10px;
}

.dropdown-item-tooltip {
  z-index: 999;
  max-width: 264px !important;
  word-break: break-all;
  text-align: center;
  margin-bottom: 10px;
}

.css-qr46ko {
  max-height: 18vh !important;
}

.css-1n6sfyn-MenuList {
  max-height: 18vh !important;
}

.disabled-class {
  * {
    cursor: not-allowed !important;
  }

  cursor: not-allowed !important;
  opacity: 0.4 !important;
}