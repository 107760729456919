@use '../../../theme/theme';

.approvalsAndRequests {
  margin: 0px;
  display: flex;
  flex: 1;
  margin-left: -2vw;

  &-sidebar {
    width: 15vw;
    display: flex;
    flex-direction: column;
    border-right: 1px solid theme.$border-color;
    position: fixed;
    height: 100vh;
    background: theme.$white-color;
    z-index: 1;
  }

  &-content {
    display: flex;
    flex: 1;
    height: calc(100vh - theme.$header-Height - 4vh);
    margin-left: 15vw;
  }

  &-sidebar-option {
    color: theme.$label-color !important;
  }
}