@use '../../../../../theme/theme';

$userCardHeight: 60px;

.userView {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-section {
    margin: 1vh 0;
  }

  &-user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - $userCardHeight - 1vw);

    &-image {
      width: $userCardHeight;
      height: $userCardHeight;
      line-height: $userCardHeight;
      font-size: calc($userCardHeight / 2);
      text-align: center;
      color: theme.$white-color;
    }

    &-container {
      display: flex;
      gap: 1vw;
    }
  }

  &-cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1vw;

    &-card {
      padding: 1vw;
      border: 1px solid theme.$border-color;
      width: 48%;

      &-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1vw;
      }
    }
  }

  &-grid {
    &-body {
      border: 1px solid theme.$border-color;
      padding: 0 1vw;

      &-row {
        font-weight: 400 !important;
        flex-wrap: nowrap;
        padding: 10px 1vw;
        border-bottom: 1px solid theme.$border-color;
        align-items: center;

        &-col {
          padding-right: 1vh;

          &-bold {
            font-weight: 500;
          }

          &-icon {
            display: inline-flex;
            gap: 1.6vw;
          }
        }
      }
    }
  }

  &-topApps {
    padding: 1vw;
    border: 1px solid theme.$border-color;
  }

  &-legends {
    &-legend {
      display: flex;
      gap: 10px;

      &-box {
        width: 20px;
        height: 20px;
      }
    }

    &-container {
      display: flex;
      gap: 2vw;
      justify-content: flex-end;
      margin: 2vh 0;
    }
  }

  &-daterange {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
}

.DDSProgressBar {
  padding: 0px !important;
}

.DDSProgressBar__base {
  height: 1.25rem !important;
  background-color: theme.$progress-bar-bg !important;
}

.Progress-bar--value {
  background-color: theme.$progress-bar-value !important;
}

.dropdown {
  width: 15vw;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 45vh;
}
