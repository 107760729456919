.DDSSearch {
  display: none;
}
.Sandbox-container .DDSHeader {
  width: unset !important;
}

.DDSHeader__projectName {
  cursor: pointer !important;
}
.user-info {
  text-transform: capitalize;
}
