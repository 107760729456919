@use "../../../theme/theme";

.checkbox-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  position: relative;
  padding-left: 15px;
  margin-bottom: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    top: 2px;
    height: 15px;
    width: 15px;
    background-color: theme.$white-color;
    border: 1px solid theme.$black-color;
  }

  input:checked~.checkmark {
    background-color: theme.$base-color;
    border-color: theme.$base-color;
  }

  .disabled,
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 2.6px;
    width: 3px;
    height: 6px;
    border: solid theme.$white-color;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}