@use '../../../../../theme/theme';
$cardHeight: 8vh;

.HoursChart {
  &-container {
    padding: 2vh 1vw;
  }

  &-body {
    padding-right: 2vw;
    max-height: 75vh;
    overflow: hidden;

    &-scroll {
      overflow: auto;
      width: calc(100% - 2vw + 5px);

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: transparent;
      }
    }


    &-row {
      flex-wrap: nowrap;
      padding: 0px 1vw;
      border-bottom: 1px solid theme.$border-color;
      align-items: center;

      &-divider {
        border-left: 1px solid theme.$border-color;
        height: calc($cardHeight + 1vh);
        // margin-right: 25%;
        margin: 0 0.5vw 0 0.5vw;
      }
    }
  }

  &-title {
    padding-right: 2vw;

    &-row {
      color: theme.$header-color !important;
      flex-wrap: nowrap;
      padding: 0px 1vw;
      align-items: center;
      border-bottom: 1px solid theme.$border-color;

      &-col {
        display: flex;

        &-icon-sort {
          &-ASC {
            transform: rotate(180deg);
          }

          &-DESC {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &-user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - ($cardHeight / 2) - 2 * 1.5vh);

    &-image {
      width: calc($cardHeight / 2);
      height: calc($cardHeight / 2);
      line-height: calc($cardHeight / 2);
      font-size: calc($cardHeight / 4);
      text-align: center;
    }

    &-container {
      display: flex;
      gap: 0.5vw;
      // padding: 1.5vh;
      width: 16vw;
    }
  }

  &-legends {
    &-legend {
      display: flex;
      gap: 10px;

      &-box {
        width: 20px;
        height: 20px;
      }
    }

    &-container {
      display: flex;
      gap: 2vw;
      justify-content: flex-end;
      margin-top: 2vh;
    }
  }
}