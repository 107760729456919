@use "../../../../theme/theme";

.scheduledReports {
  width: 100%;
  padding-left: 2vw;
  &-header {
    padding: 2vh 0;
    border-bottom: 1px solid theme.$border-color;
  }

  &-searchContainer {
    width: 100%;
    display: flex;
    gap: 1vw;
    margin: 5vh 0;
  }

  &-searchbox {
    border: 1px solid theme.$border-color;
  }
  &-grid {
    padding-right: 20vw;

    &-title {
      padding-right: 2vw;

      &-row {
        color: theme.$header-color !important;
        flex-wrap: nowrap;
        padding: 10px 1vw;
        // align-items: center;
        border-bottom: 1px solid theme.$border-color;
        word-break: normal;

        &-col {
          display: flex;
          padding-right: 1vh;
          word-break: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          &-icon-sort {
            &-ASC {
              transform: rotate(180deg);
            }

            &-DESC {
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    &-body {
      border: 1px solid theme.$border-color;
      padding: 0 1vw;

      &-row {
        font-weight: 400 !important;
        flex-wrap: nowrap;
        padding: 10px 1vw;
        border-bottom: 1px solid theme.$border-color;
        align-items: center;

        &-col {
          padding-right: 1vh;

          &-icon {
            display: inline-flex;
            gap: 1.6vw;
          }
        }
      }
    }

    &-pagination {
      display: flex;
      justify-content: center;
      padding: 4vh 0;
    }
  }
  &-nosearchdata {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2vh;
    width: 58vw;

    p {
      font-size: theme.$fs-18;
      font-weight: 600;
      padding-top: 8px;
    }
  }
}
.schedule-chips {
  border: 1px solid #0d839f;
  padding: 4px;
  width: 21px;
  color: #0d839f;
  margin-left: 5px;
}
