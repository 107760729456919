@use '../../../../../theme/theme';

.--apps-mainbox-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.--processes-top-action-box {
  display: flex;
  flex-direction: row;
}

.add-apps-button {
  margin-left: 40px !important;
}

.add-apps-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-right: 12rem;
}

.add-apps-container img {
  width: 30rem;
  height: 17rem;
}

.uncategorized-apps {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-right: 12rem;
}

.uncategorized-apps img {
  height: 15rem;
  width: 23rem;
  margin-top: 2.5rem;
}

.uncategorized-apps h3 {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2.1rem;
  margin-bottom: 1px;

}

.add-apps-container p {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0.5rem;
}

.add-apps-container h3 {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0.8rem;
  margin-bottom: 1px;
}


.add-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-top: 4rem;
  margin-right: 12rem;
}

.add-sub-container p {
  // width: 385px;
  // height: 24px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2px;
}

.add-sub-container h3 {
  font-family: Open Sans;
  font-size: 27px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;


}

.add-sub-container img {
  width: 18rem;
}

.horizontal-line {
  border-top: 1px solid #ccc;
  width: 100%;
  margin-top: 10px;
}

.--process-search-create-box {
  display: flex;
  flex-direction: row;
  gap: 3.28vw;
}

.process-searchbox {
  background: white;
  border: 1px solid #d0d0ce;
}

.process-crate-button {
  height: max(40px, 2vw);
  width: max(240px, 12vw);
}

.apps-body {
  margin-top: 10px;

  &.noresult {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 415px;
      height: 255px;
    }

    div {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      padding-top: 25px;
    }
  }

  .--processrow-title {
    color: #53565a;
    flex-wrap: nowrap;
    padding: 15px 0 7px 0;
    align-items: center;
    font-weight: 400;
    font-size: small;

    .--process-name-sort-icon {
      height: min(12px, 0.63vw);
      width: min(12px, 0.63vw);
      margin-left: 5px;
    }

    .--process-name-sort-icon.rotate180 {
      transform: rotate(180deg);
    }

    .main-grid-item {
      min-height: 24px;
      display: flex;
      align-items: center;
      word-break: break-word;
    }

    .--step-row-action {
      width: 10px;
      margin-left: 5px;
      cursor: pointer;
    }

    .--step-row-action.rotate180 {
      transform: rotate(180deg);
    }
  }

  .--processrow-none {
    text-align: center;
    font-size: 20px;

    img {
      width: 22px;
      transform: translate(5px, 4px);
    }
  }

  .--processrow:first-child {
    border-top: 1px solid #d0d0ce;
  }

  .--processrow {
    color: black;
    width: 60%;
    flex-wrap: nowrap;
    padding: 10px 5px;
    border-bottom: 1px solid #d0d0ce;
    align-items: center;

    .--process-status-cell {
      display: flex;
      flex-direction: revert;
      gap: 6px;
    }

    .--process-row-action-icon {
      width: max(18px, 0.94vw);
      height: max(18px, 0.94vw);
    }
  }
}

.editGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #d0d0ce;
  padding: 0 8px;
}



.optionmenu {
  display: none;
  position: fixed;
}

.eGctXl {
  background-color: #0d8390 !important;
}

.disabled-cursor {
  cursor: not-allowed !important;
}


.appsPage {
  &-pagination {
    // position: fixed;
    display: flex;
    bottom: 0;
    background-color: white;
    justify-content: center;
    padding: 15px 0;
    // width: -webkit-fill-available;
    width: 80%;
  }

  &-popupMenu {
    &-container {
      height: 0;
      width: 0;
      position: relative;

      &-box {
        position: relative;
        left: -1vw;
        top: 2vh;
        width: fit-content;
        background: #ffffff;
        z-index: 100;

        &-body {
          flex-flow: column nowrap;
          display: flex;
          gap: 15px;
        }
      }
    }
  }
}