.toggleButton {
  &-container {
    width: 50px;
    background-color: #c4c4c4;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    padding: 2px;
    height: 25px;
    position: relative;
  }

  &-dialog-button {
    font-size: 10px;
    line-height: 8px;
    font-weight: bold;
    cursor: pointer;
    background-color: #86BC25;
    color: white;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 2px;
    transition: all 0.3s ease;
  }

  &-disabled {
    background-color: #707070;
    right: 17px;
  }
}