@use '../theme/theme';

$height :calc(100vh - theme.$header-Height - 4vh);

.HomePageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &-container {
    max-height: $height;
    height: $height ;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2vh 2vw;
  }
}