$base-color: #0d8390;
$base-color-disabled: #9ecdd3;
$disabled-color: #777777;
$teal-color: #007680;
$blue-color: #0d2689;
$border-color: #d0d0ce;
$header-color: #53565a;
$label-color: #000000;
$disabled-input-color: #63666A;
$success-color: #26890d;
$error-color: #da291c;
$warning-color: #ffcd00;
$black-color: #000000;
$white-color: #ffffff;
$container-border-color: #bbbcbc;
$info-color: #DDEFE8;
$logs-border: #bbbcbc; //remove this
$recurring-color1: #668f1c;
$recurring-color2: #007cb0;
$recurring-color3: #b46a00;
$recurring-color4: #c29c00;
$recurring-color5: #77a19d;
$recurring-color6: #e66e65;
$recurring-color7: #ebebeb;
$recurring-color8: #cccccc;
$hover-grey-color: #e0e0e0;
$util-box-separator-color: #97999b;
$dot-color: #bbbcbc;
$recurring-color8: #cccccc;
$hover-grey-color: #e0e0e0;
$progress-bar-bg: #F1F1F0;
$progress-bar-value: #52C0EA;

$header-Height :48px;

$fs-8: max(8px, 0.56vw);
$fs-10: max(10px, 0.74vw);
$fs-12: max(12px, 0.94vw);
$fs-14: max(14px, 0.73vw);
$fs-16: max(16px, 1.00vw);
$fs-18: max(18px, 1.00vw);
$fs-20: max(20px, 1.56vw);
$fs-24: max(24px, 1.88vw);
$fs-28: max(28px, 2.19vw);
$fs-32: max(32px, 2.5vw);
$fs-36: max(36px, 2.8vw);
$fs-40: max(40px, 3.1vw);
$fs-44: max(44px, 3.4vw);
$fs-48: max(48px, 3.75vw);
$fs-52: max(52px, 4vw);
$fs-56: max(56px, 4.4vw);
$fs-60: max(60px, 4.7vw);
$fs-64: max(64px, 5vw);