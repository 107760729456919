@use '../../../../../theme/theme';

.analytics {
  cursor: default;

  &-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
  }

  border: 1px solid theme.$border-color;
  padding: 1vh;
  display: flex;
  align-items: flex-start;
  gap: 1vh;
  min-width: 20%;

  img {
    width: 6vw;
    margin: auto;
    cursor: default;
  }

  &-row {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    color: theme.$base-color;
    justify-content: space-between;
  }
}