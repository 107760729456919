@use "../../../../../theme/theme";

.page-link {
  min-width: 1vw;
  position: relative;
  display: inline-flex;
  justify-content: center;
  padding: 10px 15px;
  color: theme.$black-color;
  background-color: theme.$white-color;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:hover,
  &:focus {
    background-color: #dae1d8;
    color: theme.$base-color;
  }

  &:focus {
    z-index: 3;
  }

  &-active,
  &-active:hover {
    z-index: 2;
    color: theme.$white-color;
    background-color: theme.$base-color;
    cursor: default;
  }

  &-disabled {
    pointer-events: none;

    img {
      opacity: .5;
    }
  }
}