@use '../../../../theme/theme';

.catFilters {
  display: flex;
  align-items: flex-start;
  position: fixed;
  right: 1vw;
  top: theme.$header-Height;
  background-color: theme.$white-color;

  &-slider {
    display: flex;
    border-left: 1px solid theme.$border-color;
    height: calc(100vh - theme.$header-Height);

    &-content {
      display: flex;
      flex-direction: column;
      width: 20vw;
      padding: 4vh 10px 0px 1vw;
    }
  }

  &-dropdowns {
    margin-top: 2vh;
    padding-top: 2vh;
    flex: 1;
    border-top: 1px solid theme.$border-color;
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }

  &-footer {
    display: flex;
    gap: 1vw;
    border-top: 1px solid theme.$border-color;
    padding-block: 10px;
    justify-content: end;
  }
}