@use "../../../theme/theme";

.notify-toast-main {
    // position: fixed;
    display: flex;
    // left: 50%;
    // top: 60px;
    // height: 50px;
    // transform: translate(-50%, 0);
    // justify-content: center;
    transition: all 1s;
    // z-index: 11;
    margin: 2vh 0;

    .--msgbox {
        color: white;
        // border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.--success {
            background: theme.$success-color;
        }

        &.--error {
            background: theme.$error-color;
        }

        &.--warning {
            background: theme.$warning-color;
            color: theme.$black-color;
        }

        &.--info {
            background: theme.$info-color;
            color: theme.$black-color;
        }

        .--spanblock {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                padding: 0px 5px;
                align-self: center;
            }
        }

        img {
            padding: 0px 5px;

            &.--close {
                height: 2vh;
                align-self: center;
                cursor: pointer;
            }

            &.--type {
                align-self: center;
                margin-top: 2px;
            }
        }
    }
}