@use '../../../theme/theme';

.acessDenied {
  &-img {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    img {
      height: 60vh;
    }
  }

  .DDSUserProfile,
  .DDSHeader__projectName {
    display: none;
  }
}