@use "../../../theme/theme";

.dashboard {
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid theme.$border-color;
    padding-bottom: 2vh;
    margin-bottom: 2vh;

    &-dropdown {
      width: 20vw;
    }
  }
}