@use '../../../theme/theme';

.reports {
  margin: 0px;
  display: flex;
  flex: 1;
  margin-left: -2vw;

  &-sidebar {
    width: 18vw;
    display: flex;
    flex-direction: column;
    border-right: 1px solid theme.$border-color;
    position: fixed;
    height: 100vh;
    background: theme.$white-color;
    z-index: 1;

    &-option {
      color: theme.$label-color !important;
    }
  }

  &-content {
    display: flex;
    flex: 1;
    height: calc(100vh - theme.$header-Height - 4vh);
    margin-left: 18vw;
  }
}

.DDSButton__text {
  line-height: normal !important;
}