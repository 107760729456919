@use '../../../theme/theme';

.categoryManagement {
  margin: 0px;
  display: flex;
  flex: 1;
  margin-left: -2vw;

  &-content {
    display: flex;
    flex: 1;
    height: calc(100vh - theme.$header-Height - 4vh);
    margin-left: 15vw;
  }

  &-sidebar {
    width: 15vw;
    display: flex;
    flex-direction: column;
    border-right: 1px solid theme.$border-color;
    position: fixed;
    height: calc(100vh - 48px - 2vh);
    background: theme.$white-color;
    z-index: 1;

    &-scroll {
      overflow: auto;

      button {
        background-color: transparent;
        color: inherit;
        font-weight: inherit;
      }
    }
  }

  &-uncategorizedapps {
    margin-top: 2vh;
    border-top: 1px solid theme.$border-color;
    padding-top: 3vh;

    &-selected {

      button {
        background-color: theme.$base-color;
        color: theme.$white-color;
        font-weight: 600;

        &:hover {
          background-color: theme.$base-color;
          color: theme.$white-color;
        }
      }
    }
  }

  &-normal {
    color: theme.$label-color;
    font-weight: normal;
  }

  &-selected {
    color: theme.$white-color !important;
    background-color: theme.$base-color;
    font-weight: normal;

    &-category {
      color: theme.$base-color !important;
      font-weight: 600;
    }
  }

  &-category {
    font-weight: 600;

    &-sub {
      margin-left: .5vw;
    }
  }

  &-popup-body {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
}


.cat-sidebar-btn {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 8px;
}

.cat-sidebar-btn-sel {
  display: flex;
  justify-content: space-between;
  background-color: #0d8390;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding: 8px;
}

.cat-sidebar-btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: black;
}

.add-cat-btn {
  background-color: #0d8390;
  cursor: pointer;
  color: white;
  border-width: 0;
  padding: 8px;
  margin: 8px;
}

.action-item {
  height: max(16px, 1vw);
}

.category-update-delete {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.width-100 {
  max-width: 100%;
}

.cat-sidebar {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-c {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 74%;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #0006;
}

.empty-categories-page {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;

}

.empty-categories-page img {
  width: 369.28px;
  height: 271.6px;
  top: 0.18px;
  left: 4.51px;
}

.empty-categories-page h3 {

  font-family: Open Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 14px;
}

.empty-categories-page p {

  height: 24px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}


.scroll-c section {
  display: flex !important;
}

.app-scroll {
  max-height: 61vh !important;
  overflow: auto;
}

.category-titlebar {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 7px 0;
}

.title {
  flex: 1;
  font-weight: 600;
}

.divider {
  border-top: 1px solid theme.$border-color;
  margin-left: 8px;
  margin-right: 8px;
}

.vertical-divider {
  height: 80%;
  width: 0.5px;
  background-color: #d0d0ce;
  margin: 0 5px;
}

.Empty-categories-page {
  justify-content: center !important;
}

.deleteColor {
  background-color: theme.$error-color;
}