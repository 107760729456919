@use '../../../../theme/theme';
$cardHeight: 8vh;
$managerCardHeight: 5vw;

.teamLevel {
  &-back {
    color: theme.$base-color;
    display: inline-flex;
    gap: .5vw;
    align-items: center;
    margin-top: 2vh;
  }

  &-graph {
    &-title-container {
      padding-top: 2vh;
      align-items: center;
      display: flex;
      flex-direction: column;
      // gap: 5vh;
    }
  }

  &-divider {
    border-bottom: 1px solid theme.$border-color;
    margin: 1vw 0;
  }

  &-title {
    padding-right: 2vw;

    &-row {
      color: theme.$header-color !important;
      flex-wrap: nowrap;
      // padding: 10px 1vw;
      align-items: center;
      border-bottom: 1px solid theme.$border-color;

      &-col {
        display: flex;

        &-icon-sort {
          &-ASC {
            transform: rotate(180deg);
          }

          &-DESC {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  &-body {
    border: 1px solid theme.$border-color;
    padding: 0 1vw;


    &-row {
      flex-wrap: nowrap;
      padding: 1vw;
      border-bottom: 1px solid theme.$border-color;
      align-items: center;

      &-empty {
        border-bottom: 0px !important;
        flex-wrap: nowrap;
        padding: 1vw;
        align-items: center;
      }

      &-hover {
        fill: theme.$white-color ;

        &:hover {
          background: theme.$border-color;
          fill: theme.$black-color ;
        }

        &-threedotsIcon {
          transform: rotate(90deg);
          fill: inherit;
        }
      }

      &-divider {
        border-left: 1px solid theme.$border-color;
        height: 11.5vh;
        // margin-right: 25%;
        margin: 0 0.5vw 0 0.5vw;
      }

      &-cardDivider {
        border-left: 1px solid theme.$border-color;
        height: 25vh;
      }

    }
  }

  &-header {
    margin-top: 2vh;
  }

  &-apps-container {
    margin-top: 2vh;
  }

  &-user-selected {
    width: calc(100% - $cardHeight - 2 * 1.5vh) !important;
  }

  &-user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - ($cardHeight / 2) - 0.5vw);

    &-details {
      display: flex;
      flex-direction: column;

      &-count {
        border: 1px solid theme.$base-color;
        color: theme.$base-color !important;
        width: max-content;
        padding: 0.5vh 1vh;

        &-container {
          display: flex;
          justify-content: end;
        }
      }

      &-countCard {
        border: 1px solid theme.$base-color;
        color: theme.$base-color !important;
        width: max-content;
        padding: 0.5vh 1vh;

        &-container {
          margin-top: auto;
        }
      }
    }

    &-image {
      width: $cardHeight;
      height: $cardHeight;
      line-height: $cardHeight;
      font-size: calc($cardHeight / 2);
      text-align: center;
      color: theme.$white-color;

      &-card {
        width: calc($cardHeight / 2);
        height: calc($cardHeight / 2);
        line-height: calc($cardHeight / 2);
        font-size: calc($cardHeight / 4);
        text-align: center;
        background-color: theme.$warning-color;
      }
    }

    &-container {
      border: 1px solid theme.$border-color;
      display: flex;
      gap: 1vw;
      padding: 1.5vh;
      width: 17vw;
    }

    &-containerUser {
      display: flex;
      gap: 0.5vw;
      padding: 1vh 0 1vh 0;
      width: 17vw;
    }
  }

  &-jobTitle {
    &-container {
      display: flex;
      gap: 1vw;
      justify-content: space-between;
    }
  }

  &-manager {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - $managerCardHeight - 1vw);

    &-details {

      &-count {
        border: 1px solid theme.$base-color;
        color: theme.$base-color !important;
        width: max-content;
        padding: 0.5vh 1vh;

        &-container {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
    }

    &-image {
      width: $managerCardHeight;
      height: $managerCardHeight;
      line-height: $managerCardHeight;
      font-size: calc($managerCardHeight / 2);
      text-align: center;
      color: theme.$white-color;
    }

    &-arrow {
      height: 3vh;
      border: 1px solid theme.$border-color;
      width: 0;
      margin: auto;
    }

    &-container {
      border: 1px solid theme.$border-color;
      display: flex;
      gap: 1vw;
      padding: 1.5vh;
      width: 20vw;
    }

  }

  &-legends {
    &-legend {
      display: flex;
      gap: 10px;

      &-box {
        width: 20px;
        height: 20px;
      }
    }

    &-container {
      display: flex;
      gap: 2vw;
      justify-content: flex-end;
      margin: 2vh 0;
    }
  }

  &-addMore {
    display: flex;
    justify-content: end;
    margin: 2vh 0
  }


  &-action {
    &-menu {
      height: 0;
      width: 0;
    }

    &-options {
      position: relative;
      z-index: 100;
      left: -10vw;
      top: 1vh;
      width: max-content;
      padding: 0.5vh;
      flex-flow: column nowrap;
      background: theme.$white-color;
      border: 1px solid theme.$border-color;
      text-align: center;

      &-item {
        padding: 1vh 2vh;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &:hover {
          background: theme.$border-color;
        }
      }
    }
  }

  &-appname {
    display: flex;
    flex-direction: column;
    width: 95%;
    cursor: default;
  }
}