@use '../../../theme/theme';


//tooltip
$tooltip--padding: 7px 12px 9px;
$tooltip--bg: theme.$black-color;
$tooltip_error--bg: theme.$error-color;
$tooltip--color: theme.$white-color;
$popup-beak--size: 1vh;

$tooltip: "tooltip";
$wraper: $tooltip + "-wrapper";
$tooltip-margin: 40px;

.#{$wraper} {
  display: inline-block;
  position: relative;

  .#{$tooltip} {
    font: theme.$fs-12;
    position: absolute;
    padding: 6px;
    padding: $tooltip--padding;
    background: $tooltip--bg;
    color: $tooltip--color;
    z-index: 100;
    // white-space: nowrap;

    &.no-beak::before {
      display: none;
    }

    &:before {
      content: " ";
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: $popup-beak--size;
      margin-left: calc($popup-beak--size * -1);
    }

    &_top {
      bottom: calc($tooltip-margin * 1);

      &:before {
        top: 100%;
        border-top-color: $tooltip--bg;
      }
    }

    &_bottom {
      top: calc($tooltip-margin * 1);

      &:before {
        bottom: 100%;
        border-bottom-color: $tooltip--bg;
      }
    }

    &_left {
      left: auto;
      right: calc(100% + $tooltip-margin / 2);

      &:before {
        left: auto;
        right: calc($popup-beak--size * -2);
        border-left-color: $tooltip--bg;
      }
    }

    &_right {
      left: calc(100% + $tooltip-margin / 2);

      &:before {
        left: calc($popup-beak--size * -1);
        border-right-color: $tooltip--bg;
      }
    }

    &_error {
      background: $tooltip_error--bg;

      &_top:before {
        border-top-color: $tooltip_error--bg;
      }

      &_bottom:before {
        border-bottom-color: $tooltip_error--bg;
      }

      &_left:before {
        border-left-color: $tooltip_error--bg;
      }

      &_right:before {
        border-right-color: $tooltip_error--bg;
      }
    }
  }

  .beak {

    &_top,
    &_bottom {
      left: 50%;
      transform: translateX(-50%);

      &:before {
        left: 50%;
        // transform: translateX(-50%);
      }
    }

    &_top-right,
    &_bottom-right {
      left: 0%;
      transform: translateX(0%);
      // &:before {
      //   left: 10%;
      //   transform: translateX(10%);
      // }
    }

    &_top-left,
    &_bottom-left {
      left: 100%;
      transform: translateX(-100%);

      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
    }

    &_left,
    &_right {
      top: 50%;
      transform: translateX(0) translateY(-50%);
      white-space: normal;

      &:before {
        top: 50%;
        transform: translateX(0) translateY(-50%);
      }
    }

    &_right-top,
    &_left-top {
      top: 0%;
      transform: translateX(0) translateY(0%);

      &:before {
        top: 5%;
        transform: translateX(0) translateY(5%);
      }
    }

    &_right-bottom,
    &_left-bottom {
      top: 100%;
      transform: translateX(0) translateY(-100%);

      &:before {
        top: 95%;
        transform: translateX(0) translateY(-95%);
      }
    }
  }
}