.searchbox-main {
    width: 200px;
    background: #FFFFFF;
    align-items: center;
    display: inline-flex;
}
.custom-input{
    border: none;
    outline: none;
    height: 30px;
    padding-left: 10px;
}
.close-action-item{
    cursor: pointer;
    padding: 5px;
}